/* logo {
  height: 2rem;
  width: 2rem;
} */
.multi-bg {
  /* width: 100%; */
  /* height: 400px; */
  /* background-image: url("./images/bg.png"), url("./images/logo.png"),
    linear-gradient(to right, rgba(30, 75, 115, 1), rgba(255, 255, 255, 0)); */
  background-image: url("./images/bg.png");
  /* background-position: center;*/
  background-size: cover;

  /* background-repeat: no-repeat, no-repeat, no-repeat;  
  background-position: bottom right, left, right; */
}
.new-bg {
  background-image: url("./images/orange.png");
  background-size: cover;
  max-width: 100% !important;
  overflow-x: hidden !important;
  height: 10rem;
}
.other-bg {
  height: 10rem;
  background-image: url("./images/orangeblk.png");
  background-size: cover;
  max-width: 100% !important;
  overflow-x: hidden !important;
}
.black-flipped {
  height: 10rem;
  background-image: url("./images/blackcurve.png");
  background-size: cover;
  max-width: 100% !important;
  overflow-x: hidden !important;
}
body {
  overflow-x: hidden !important;
}

.button {
  color: "#FFA500";
  font-family: "Raleway";
}
.feature-img {
  /* height:20rem; */
  box-shadow: 0.5rem 0.5rem rgb(191, 151, 8), -0.5rem -0.5rem grey;
}

@media only screen and (max-width: 1200px) {
  .three-rounds {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  .three-rounds {
    display: flex;
    align-items: end;
    margin-left: 20px;
  }
}
.circle {
  display: block;
  background: #ffaf1a;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 1%;
  height: 300px;
  width: 300px;
  margin: 0;
}
.circle-2 {
  display: block;
  background: #ffdb99;
  border-top-left-radius: 1%;
  border-bottom-left-radius: 50%;
  height: 300px;
  width: 300px;
  margin: 0;
}
.hello {
  width: 100%;
  height: 100px;
  border-radius: 50%;
  background-color: black;
}

.feature-icons {
  /* display: flex;
  justify-content: center; */
  align-items: start;
  font-size: 90px;
  padding: 30px;
}
.feature-small-icons {
  align-items: start;
  font-size: 60px;
  padding: 20px;
}
.dropbtn {
  /* background-color: #04aa6d; */
  /* color: white; */
  padding: 16px;
  font-size: 16px;
  /* height : 15px; */
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  /* position: absolute; */
  background-color: #000000;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: rgba(255, 255, 255, 0);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  /* z-index: 100; */
}

.my-float {
  margin-top: 16px;
}
.sidepanel {
  width: 100%;
  position: fixed;
  z-index: 102;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffaf1a;
  overflow-x: hidden;
  transition: 0.6s;
  padding-top: 20px;
}
.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}
a { color: inherit; } 
